import React from "react";
import PropTypes from "prop-types";
import Disabled from "components/atoms/Disabled";
import "./style.scss";

const ColorCircle = ({
  name,
  selected,
  soldout,
  onClick,
  onMouseEnter,
  onMouseLeave,
  qaName,
  hexCode,
}) => {
  const cleanedQaName = String(qaName).replace(" ", "");
  return (
    <button
      className={`msd-color-circle ${
        selected ? "msd-color-circle--selected" : "msd-color-circle--default"
      } msd-color-circle--${cleanedQaName}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-qa={qaName}
      aria-label={`Gerätefarbe ${name}`}
      style={{ backgroundColor: hexCode }}
    >
      {soldout && <Disabled />}
    </button>
  );
};

ColorCircle.defaultProps = {
  selected: false,
  variant: "default",
  soldout: false,
  className: "",
  hexCode: "",
  onClick: () => null,
  onMouseEnter: () => null,
  onMouseLeave: () => null,
};

ColorCircle.propTypes = {
  selected: PropTypes.bool,
  soldout: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  qaName: PropTypes.string,
  hexCode: PropTypes.string,
};

export default ColorCircle;
